import _defineProperty from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
var __jsx = React.createElement;

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { useRouter } from "next/router";
import React from "react";
import { Image } from "@/components/common";
import { Command, ImagePlacecholder } from "@/components/ui";
import { PRODUCT_VARIANT_VOLUME_ATTRIBUTE } from "@/config/common.config";
import { getAttribute } from "@/helpers/attribute.helper";
import { translate } from "@/helpers/translation.helpers";
import usePath from "@/hooks/usePath.hook";
import useRegion from "@/hooks/useRegion.hook";
import { cn } from "@/libraries/clsx.library";
import { pushGtmEvent } from "~/src/helpers/analytics.helper";
import { isHiddenOnStorefront, isLowPriceZoneVariant } from "~/src/helpers/product.helpers";
import styles from "./SearchProduct.styles";

var SearchProduct = function SearchProduct(_ref) {
  var _variant$pricing, _variant$pricing2, _getAttribute, _variant$pricing3, _variant$pricing4, _variant$pricing5;

  var variant = _ref.variant,
      product = _ref.product,
      searchInput = _ref.searchInput;

  var _useRegion = useRegion(),
      formatPrice = _useRegion.formatPrice;

  var _usePath = usePath(),
      _slug = _usePath._slug;

  var router = useRouter();
  var name = translate(product, "name");
  var priceUndiscounted = formatPrice((_variant$pricing = variant.pricing) === null || _variant$pricing === void 0 || (_variant$pricing = _variant$pricing.priceUndiscounted) === null || _variant$pricing === void 0 ? void 0 : _variant$pricing.gross);
  var price = formatPrice((_variant$pricing2 = variant.pricing) === null || _variant$pricing2 === void 0 || (_variant$pricing2 = _variant$pricing2.price) === null || _variant$pricing2 === void 0 ? void 0 : _variant$pricing2.gross);
  var volume = (_getAttribute = getAttribute(PRODUCT_VARIANT_VOLUME_ATTRIBUTE, variant === null || variant === void 0 ? void 0 : variant.attributes)) === null || _getAttribute === void 0 ? void 0 : _getAttribute.value[0];
  var lowPriceZoneVariant = isLowPriceZoneVariant(variant);
  var hiddenOnStorefront = isHiddenOnStorefront(product);
  var findMediaUrl = React.useCallback(function (productMedia) {
    var _productMedia$find;

    return productMedia === null || productMedia === void 0 || (_productMedia$find = productMedia.find(function (m) {
      return m.type === "IMAGE";
    })) === null || _productMedia$find === void 0 ? void 0 : _productMedia$find.url;
  }, []);
  var media128Url = findMediaUrl(variant === null || variant === void 0 ? void 0 : variant.media128);
  var handleSelect = React.useCallback(function (phrase) {
    var _variant$name;

    var url = {
      pathname: _slug(product.slug).$url().pathname,
      query: _objectSpread(_objectSpread({}, _slug(product.slug).$url().query), {}, {
        variant: variant === null || variant === void 0 ? void 0 : variant.id
      }, lowPriceZoneVariant ? {
        "low-price-zone": true
      } : undefined)
    };
    pushGtmEvent({
      event: "search_selected",
      search: {
        phrase: phrase,
        type: "product",
        variant: (_variant$name = variant.name) !== null && _variant$name !== void 0 ? _variant$name : product.name,
        sku: variant.sku
      }
    });
    router.push(url);
  }, [_slug, lowPriceZoneVariant, product.name, product.slug, router, variant === null || variant === void 0 ? void 0 : variant.id, variant.name, variant.sku]);

  if (hiddenOnStorefront) {
    return null;
  }

  return __jsx(Command.Item, {
    className: styles.root(),
    value: "".concat(name, " ").concat(volume, " ").concat(price, " ").concat(priceUndiscounted),
    onSelect: function onSelect() {
      return handleSelect(searchInput !== null && searchInput !== void 0 ? searchInput : "");
    }
  }, __jsx("div", {
    className: styles.imageContainer()
  }, media128Url ? __jsx(Image, {
    src: media128Url || "",
    alt: "Product image",
    placeholder: "blur",
    unoptimized: true,
    fill: true,
    sizes: "100vw",
    style: {
      objectFit: "cover",
      objectPosition: "center"
    }
  }) : __jsx(ImagePlacecholder, {
    size: "xl"
  })), __jsx("div", {
    className: styles.info()
  }, __jsx("div", {
    className: styles.details()
  }, __jsx("span", {
    className: styles.name()
  }, name), __jsx("span", {
    className: styles.volume()
  }, volume)), __jsx("div", {
    className: cn(styles.details({
      end: true
    }))
  }, (_variant$pricing3 = variant.pricing) !== null && _variant$pricing3 !== void 0 && _variant$pricing3.onSale && ((_variant$pricing4 = variant.pricing) === null || _variant$pricing4 === void 0 || (_variant$pricing4 = _variant$pricing4.priceUndiscounted) === null || _variant$pricing4 === void 0 || (_variant$pricing4 = _variant$pricing4.gross) === null || _variant$pricing4 === void 0 ? void 0 : _variant$pricing4.amount) !== ((_variant$pricing5 = variant.pricing) === null || _variant$pricing5 === void 0 || (_variant$pricing5 = _variant$pricing5.price) === null || _variant$pricing5 === void 0 ? void 0 : _variant$pricing5.gross.amount) ? __jsx("span", {
    className: styles.priceUndiscounted()
  }, priceUndiscounted) : null, __jsx("span", {
    className: styles.price()
  }, price))));
};

SearchProduct.displayName = "SearchProduct";
export default SearchProduct;