import _defineProperty from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { css as _css } from "../../../../libraries/stitches.library.ts";
var styles = {
  root: _css(_objectSpread({}, {
    "display": "flex",
    "alignItems": "flex-start !important",
    "gap": "0.5rem",
    "paddingLeft": "0.5rem !important",
    "@media (min-width: 768px)": {
      "alignItems": "center !important",
      "gap": "1rem"
    }
  })),
  info: _css(_objectSpread({}, {
    "display": "flex",
    "flex": "1 1 0%",
    "justifyContent": "space-between",
    "gap": "1rem",
    "overflow": "hidden",
    "paddingRight": "0.5rem"
  })),
  imageContainer: _css(_objectSpread({}, {
    "position": "relative",
    "height": "3rem",
    "width": "3rem",
    "flexShrink": "0",
    "alignItems": "flex-start",
    "overflow": "hidden",
    "borderRadius": "0.5rem",
    "borderWidth": "1px",
    "--tw-border-opacity": "1",
    "borderColor": "hsl(359 4% 95% / var(--tw-border-opacity))"
  })),
  name: _css(_objectSpread(_objectSpread({}, {
    "fontWeight": "500",
    "transitionProperty": "color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter",
    "transitionTimingFunction": "linear",
    "transitionDuration": "300ms",
    "animationDuration": "300ms",
    "animationTimingFunction": "linear"
  }), {}, {
    "&:hover": {
      color: "$default"
    }
  })),
  details: _css(_objectSpread(_objectSpread({}, {
    "display": "flex",
    "flexDirection": "column",
    "gap": "0.25rem",
    "overflow": "hidden",
    "@media (min-width: 768px)": {
      "flexDirection": "row",
      "gap": "0.5rem"
    }
  }), {}, {
    variants: {
      end: {
        "true": _objectSpread({}, {
          "flexShrink": "0",
          "alignItems": "flex-end"
        })
      }
    }
  })),
  price: _css(_objectSpread({}, {
    "fontWeight": "500"
  })),
  priceUndiscounted: _css({
    color: "$gray6",
    textDecoration: "line-through"
  }),
  volume: _css(_objectSpread({}, {
    "--tw-text-opacity": "1",
    "color": "hsl(359 4% 45% / var(--tw-text-opacity))"
  }))
};
export default styles;