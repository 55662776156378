var __jsx = React.createElement;
import { ChevronLeftIcon } from "@heroicons/react/24/outline";
import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useIntl } from "react-intl";
import { Button, Checkbox } from "@/components/ui";
import useCookieConsent from "@/hooks/useCookieConsent.hook";
import useInterface from "~/src/hooks/useInterface.hook";
import styles from "./Settings.styles";

var Settings = function Settings(_ref) {
  var setActiveSection = _ref.setActiveSection;
  var t = useIntl();

  var _useCookieConsent = useCookieConsent(),
      setAllowedCookieGroups = _useCookieConsent.setAllowedCookieGroups;

  var _useInterface = useInterface(),
      closeDialog = _useInterface.closeDialog;

  var ctx = useForm({
    defaultValues: {
      functional: false,
      analytics: false,
      marketing: false
    }
  });
  var handleSubmit = ctx.handleSubmit(function (formData) {
    var functional = formData.functional,
        analytics = formData.analytics,
        marketing = formData.marketing;

    if (!functional && !analytics && !marketing) {
      setAllowedCookieGroups([]);
    } else {
      var allowedCookieGroups = [];
      var cookieGroups = ["functional", "analytics", "marketing"];
      cookieGroups.forEach(function (group) {
        if (formData[group]) {
          allowedCookieGroups.push(group);
        }
      });
      setAllowedCookieGroups(allowedCookieGroups);
    }

    closeDialog();
  });
  return __jsx(FormProvider, ctx, __jsx("form", {
    onSubmit: handleSubmit,
    className: styles.root()
  }, __jsx("div", {
    className: styles.content()
  }, __jsx("button", {
    type: "button",
    className: styles.backButton(),
    onClick: function onClick() {
      return setActiveSection("main");
    }
  }, __jsx(ChevronLeftIcon, {
    className: styles.backButtonIcon()
  }), " ", t.formatMessage({
    id: "common.return",
    defaultMessage: "Return"
  })), __jsx("div", {
    className: styles.options()
  }, __jsx("p", {
    className: styles.optionsDescription()
  }, t.formatMessage({
    id: "cookieConsent.settings.description",
    defaultMessage: "Thanks to cookies and related technologies and the processing of your data, we can ensure that we tailor the displayed content to you.\nBy consenting to the storage of information on the end device or access to it and the processing of data (including in the area of profiling, market and statistical analyses), you make it easier at LOVA to find exactly what you are looking for and need to take care of your femininity and nurture your intimate and sexual health. \nThe administrator of your data will be LOVA and some partners with whom we cooperate"
  })), __jsx("div", {
    className: styles.option()
  }, __jsx(Checkbox, {
    name: "functional",
    inputColor: "lightFlavour",
    labelColor: "dark",
    labelSize: "sm",
    center: true
  }, t.formatMessage({
    id: "cookieConsent.settings.functionalCookies.title",
    defaultMessage: "Functional cookies"
  })), __jsx("span", {
    className: styles.optionDescription()
  }, t.formatMessage({
    id: "cookieConsent.settings.functionalCookies.description",
    defaultMessage: "Functional cookies remember choices you make, providing you with better-tailored services."
  }))), __jsx("div", {
    className: styles.option()
  }, __jsx(Checkbox, {
    name: "analytics",
    inputColor: "lightFlavour",
    labelColor: "dark",
    labelSize: "sm",
    center: true
  }, t.formatMessage({
    id: "cookieConsent.settings.analyticalCookies.title",
    defaultMessage: "Analytical cookies"
  })), __jsx("span", {
    className: styles.optionDescription()
  }, t.formatMessage({
    id: "cookieConsent.settings.analyticalCookies.description",
    defaultMessage: "Analytical cookies measure the number of visits and collect information about traffic sources. We use them to improve the performance of LOVA. They help us learn which pages are most popular or how you navigate our site. Lack of consent does not affect the use of the website."
  }))), __jsx("div", {
    className: styles.option()
  }, __jsx(Checkbox, {
    name: "marketing",
    inputColor: "lightFlavour",
    labelColor: "dark",
    labelSize: "sm",
    center: true
  }, t.formatMessage({
    id: "cookieConsent.settings.marketingCookies.title",
    defaultMessage: "Marketing (advertising) cookies"
  })), __jsx("span", {
    className: styles.optionDescription()
  }, t.formatMessage({
    id: "cookieConsent.settings.marketingCookies.description",
    defaultMessage: "Marketing (advertising) cookies support the display of advertisements that are more tailored to your interests and needs, and at the same time valuable for publishers and advertisers. They are also used to display advertisements on other websites. Lack of consent does not affect the use of the website."
  }))), __jsx("div", {
    className: styles.option({
      required: true
    })
  }, __jsx(Checkbox, {
    name: "required",
    inputColor: "lightFlavour",
    labelColor: "dark",
    labelSize: "sm",
    center: true,
    checked: true,
    disabled: true
  }, t.formatMessage({
    id: "cookieConsent.settings.requiredCookies.title",
    defaultMessage: "Required cookies"
  })), __jsx("span", {
    className: styles.optionDescription()
  }, t.formatMessage({
    id: "cookieConsent.settings.requiredCookies.description",
    defaultMessage: "Cookies necessary for the proper or optimized operation of LOVA and the correct operation of the services we offer."
  }))))), __jsx("div", {
    className: styles.buttons()
  }, __jsx(Button, {
    type: "submit",
    variant: "oval",
    color: "flavor",
    className: styles.acceptButton()
  }, t.formatMessage({
    id: "cookieConsent.settings.submitButton",
    defaultMessage: "I confirm my choice"
  })))));
};

Settings.displayName = "Settings";
export default Settings;