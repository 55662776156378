import _defineProperty from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { css as _css } from "../../../libraries/stitches.library.ts";
var styles = {
  panelBg: _css(_objectSpread(_objectSpread({}, {
    "position": "fixed",
    "inset": "0px",
    "zIndex": "40"
  }), {}, {
    "&::before": _objectSpread(_objectSpread({}, {
      "position": "absolute",
      "inset": "0px",
      "opacity": "0.3",
      "--tw-content": "''",
      "content": "var(--tw-content)"
    }), {}, {
      backgroundColor: "$black"
    })
  })),
  panelWrapper: _css(_objectSpread({}, {
    "position": "fixed",
    "bottom": "0px",
    "left": "0px",
    "zIndex": "1010",
    "width": "100%"
  })),
  panel: _css(_objectSpread(_objectSpread({}, {
    "paddingTop": "1rem"
  }), {}, {
    backgroundColor: "$white",
    "@sm": _objectSpread({}, {
      "paddingTop": "1.5rem"
    })
  })),
  panelInnerContainer: _css(_objectSpread({}, {
    "paddingLeft": "0px",
    "paddingRight": "0px"
  }))
};
export default styles;