import _defineProperty from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { css as _css } from "../../../../libraries/stitches.library.ts";
var styles = {
  root: _css(_objectSpread(_objectSpread({}, {
    "display": "flex",
    "flexDirection": "column"
  }), {}, {
    maxHeight: "60vh"
  })),
  content: _css(_objectSpread(_objectSpread({}, {
    "overflowY": "auto",
    "paddingLeft": "1rem",
    "paddingRight": "1rem"
  }), {}, {
    "@sm": _objectSpread({}, {
      "paddingLeft": "1.5rem",
      "paddingRight": "1.5rem"
    })
  })),
  backButton: _css(_objectSpread({}, {
    "display": "flex",
    "alignItems": "center",
    "gap": "0.25rem"
  })),
  backButtonIcon: _css(_objectSpread({}, {
    "height": "1.5rem"
  })),
  options: _css(_objectSpread(_objectSpread({}, {
    "marginTop": "1rem",
    "> :not([hidden]) ~ :not([hidden])": {
      "--tw-space-y-reverse": "0",
      "marginTop": "calc(1rem * calc(1 - var(--tw-space-y-reverse)))",
      "marginBottom": "calc(1rem * var(--tw-space-y-reverse))"
    }
  }), {}, {
    "@sm": _objectSpread({}, {
      "marginTop": "1.5rem"
    })
  })),
  optionsDescription: _css({
    color: "$gray7",
    fontSize: "$2",
    whiteSpace: "pre-wrap"
  }),
  option: _css(_objectSpread(_objectSpread({}, {
    "> :not([hidden]) ~ :not([hidden])": {
      "--tw-space-y-reverse": "0",
      "marginTop": "calc(0.5rem * calc(1 - var(--tw-space-y-reverse)))",
      "marginBottom": "calc(0.5rem * var(--tw-space-y-reverse))"
    }
  }), {}, {
    input: _objectSpread(_objectSpread({}, {
      "height": "1.25rem",
      "width": "1.25rem"
    }), {}, {
      "&:hover": {
        "&+ div": {
          "&> div": {
            color: "$default"
          }
        }
      },
      "&[type='checkbox']:checked": {
        "&+ div": {
          "&> div": {
            color: "$default"
          }
        }
      },
      "@media (hover: hover) and (pointer: fine)": {
        "&+ div": {
          "&:hover": {
            "&> div": {
              color: "$default"
            }
          },
          "&> div": _objectSpread({}, {
            "transitionProperty": "color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter",
            "transitionTimingFunction": "cubic-bezier(0.4, 0, 0.2, 1)",
            "transitionDuration": "150ms"
          })
        }
      }
    }),
    variants: {
      required: {
        "true": _objectSpread(_objectSpread({}, {
          "pointerEvents": "none"
        }), {}, {
          input: {
            "&[type='checkbox']:checked": {
              backgroundColor: "$gray0",
              backgroundImage: "url(\"data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='Gray' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e\")"
            },
            "&+ div": {
              "&> div": {
                color: "$gray7 !important"
              }
            }
          }
        })
      }
    }
  })),
  optionDescription: _css(_objectSpread(_objectSpread({}, {
    "display": "inline-block",
    "paddingLeft": "2rem"
  }), {}, {
    color: "$gray7",
    fontSize: "$2"
  })),
  buttons: _css(_objectSpread(_objectSpread({}, {
    "flex": "1 1 0%",
    "padding": "1rem"
  }), {}, {
    "@sm": _objectSpread({}, {
      "padding": "1.5rem"
    })
  })),
  acceptButton: _css(_objectSpread(_objectSpread({}, {
    "width": "100%"
  }), {}, {
    "@sm": _objectSpread(_objectSpread({}, {
      "width": "auto"
    }), {}, {
      minWidth: "244px"
    })
  }))
};
export default styles;