import _defineProperty from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { css as _css } from "../../../../libraries/stitches.library.ts";
var styles = {
  root: _css(_objectSpread(_objectSpread({}, {
    "display": "flex",
    "flexDirection": "column"
  }), {}, {
    maxHeight: "60vh"
  })),
  content: _css(_objectSpread(_objectSpread({}, {
    "overflowY": "auto",
    "paddingLeft": "1rem",
    "paddingRight": "1rem"
  }), {}, {
    "@sm": _objectSpread({}, {
      "paddingLeft": "1.5rem",
      "paddingRight": "1.5rem"
    })
  })),
  title: _css({
    fontFamily: "$tenor",
    lineHeight: "$1",
    fontSize: "22px",
    "@xs": {
      fontSize: "26px"
    }
  }),
  description: _css(_objectSpread(_objectSpread({}, {
    "marginTop": "1rem",
    "> :not([hidden]) ~ :not([hidden])": {
      "--tw-space-y-reverse": "0",
      "marginTop": "calc(1rem * calc(1 - var(--tw-space-y-reverse)))",
      "marginBottom": "calc(1rem * var(--tw-space-y-reverse))"
    }
  }), {}, {
    "& > p": {
      color: "$gray7",
      fontSize: "$2",
      whiteSpace: "pre-line",
      "& > a": {
        color: "hsl(0, 0%, 60%)",
        outline: "none"
      }
    }
  })),
  buttons: _css(_objectSpread(_objectSpread({}, {
    "display": "flex",
    "flexDirection": "column",
    "justifyContent": "center",
    "gap": "1.25rem",
    "padding": "1rem"
  }), {}, {
    "@sm": _objectSpread({}, {
      "flexDirection": "row",
      "alignItems": "center",
      "justifyContent": "flex-start",
      "gap": "2.5rem",
      "padding": "1.5rem"
    })
  })),
  acceptButton: _css({
    "@sm": {
      minWidth: "244px"
    }
  }),
  advancedSettingsButton: _css({
    color: "$gray7"
  })
};
export default styles;