import _slicedToArray from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";
var __jsx = React.createElement;
import { Dialog } from "@headlessui/react";
import React from "react";
import { pushGtmEvent } from "~/src/helpers/analytics.helper";
import useInterface from "~/src/hooks/useInterface.hook";
import { SearchCommand } from "./components";
import styles from "./Search.styles";

var View = function View(_ref) {
  var _ref$onClose = _ref.onClose,
      onClose = _ref$onClose === void 0 ? function () {
    return null;
  } : _ref$onClose;

  var _useInterface = useInterface(),
      activeDialog = _useInterface.activeDialog,
      closeDialog = _useInterface.closeDialog;

  var _React$useState = React.useState(""),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      gtmEventData = _React$useState2[0],
      setGtmEventData = _React$useState2[1];

  var handleClose = React.useCallback(function () {
    onClose();
    pushGtmEvent({
      event: "search_discarded",
      search: {
        phrase: gtmEventData
      }
    });
    closeDialog();
  }, [closeDialog, gtmEventData, onClose]);
  return __jsx(Dialog, {
    as: "div",
    className: styles.dialog(),
    open: activeDialog === "SEARCH",
    onClose: handleClose
  }, __jsx("div", {
    className: styles.background()
  }), __jsx("div", {
    className: styles.wrapper()
  }, __jsx(Dialog.Panel, {
    className: styles.panel()
  }, __jsx(SearchCommand, {
    setGtmEventData: setGtmEventData
  }))));
};

View.displayName = "Search";
export default View;