import _slicedToArray from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";
var __jsx = React.createElement;
import { Dialog } from "@headlessui/react";
import { useRouter } from "next/router";
import React from "react";
import { Container } from "@/components/ui";
import { PAGE_PRIVACY_POLICY_SLUG } from "@/config/common.config";
import { Main, Settings } from "@/cookieConsent/sections";
import useCookieConsent from "@/hooks/useCookieConsent.hook";
import usePath from "@/hooks/usePath.hook";
import useInterface from "~/src/hooks/useInterface.hook";
import styles from "./CookieConsent.styles";

var View = function View() {
  var _useInterface = useInterface(),
      activeDialog = _useInterface.activeDialog,
      openDialog = _useInterface.openDialog;

  var path = usePath();

  var _useRouter = useRouter(),
      pathname = _useRouter.pathname;

  var _React$useState = React.useState("main"),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      activeSection = _React$useState2[0],
      setActiveSection = _React$useState2[1];

  var _useCookieConsent = useCookieConsent(),
      allowedCookieGroups = _useCookieConsent.allowedCookieGroups;

  var excludedPathNames = React.useMemo(function () {
    return [path.collect.$url().pathname, path.restore.$url().pathname, path.page._slug(PAGE_PRIVACY_POLICY_SLUG).$url().pathname];
  }, [path]);
  var renderSection = React.useCallback(function (section, setSection) {
    switch (section) {
      case "main":
        return __jsx(Main, {
          setActiveSection: setSection
        });

      case "settings":
        return __jsx(Settings, {
          setActiveSection: setSection
        });
    }
  }, []);
  React.useEffect(function () {
    if (!allowedCookieGroups && !excludedPathNames.includes(pathname) && !activeDialog) {
      openDialog("COOCKIE_CONSENT");
    }
  }, [excludedPathNames, pathname, allowedCookieGroups, activeDialog, openDialog]);
  return __jsx(Dialog, {
    as: "div",
    open: activeDialog === "COOCKIE_CONSENT",
    onClose: function onClose() {
      return null;
    }
  }, __jsx("div", {
    className: styles.panelBg()
  }), __jsx("div", {
    className: styles.panelWrapper()
  }, __jsx(Dialog.Panel, {
    className: styles.panel()
  }, __jsx(Container, {
    className: {
      box: styles.panelInnerContainer()
    }
  }, renderSection(activeSection, setActiveSection)))));
};

View.displayName = "CookieConsent";
export default View;