var __jsx = React.createElement;
import Link from "next/link";
import React from "react";
import { useIntl } from "react-intl";
import { Button } from "@/components/ui";
import { PAGE_PRIVACY_POLICY_SLUG } from "@/config/common.config";
import useCookieConsent from "@/hooks/useCookieConsent.hook";
import usePath from "@/hooks/usePath.hook";
import useInterface from "~/src/hooks/useInterface.hook";
import styles from "./Main.styles";

var Main = function Main(_ref) {
  var setActiveSection = _ref.setActiveSection;

  var _useCookieConsent = useCookieConsent(),
      setAllowedCookieGroups = _useCookieConsent.setAllowedCookieGroups;

  var _useInterface = useInterface(),
      closeDialog = _useInterface.closeDialog;

  var t = useIntl();
  var path = usePath();

  var handleAcceptClick = function handleAcceptClick() {
    setAllowedCookieGroups(["functional", "analytics", "marketing"]);
    closeDialog();
  };

  var handleAdvancedSettingsClick = function handleAdvancedSettingsClick() {
    setActiveSection("settings");
  };

  var scrollableRef = React.useRef(null);
  React.useEffect(function () {
    var timeout = setTimeout(function () {
      if (scrollableRef.current) {
        scrollableRef.current.scrollTop = 0;
      }
    }, 0);
    return function () {
      return clearTimeout(timeout);
    };
  }, []);
  return __jsx("div", {
    className: styles.root()
  }, __jsx("div", {
    ref: scrollableRef,
    className: styles.content()
  }, __jsx("h2", {
    className: styles.title()
  }, t.formatMessage({
    id: "cookieConsent.main.title",
    defaultMessage: "We care about your privacy"
  })), __jsx("div", {
    className: styles.description()
  }, __jsx("p", null, t.formatMessage({
    id: "cookieConsent.main.description1",
    defaultMessage: "Thanks to cookies and related technologies and the processing of your data, we can ensure that we tailor the displayed content to you.\nBy consenting to the storage of information on the end device or access to it and the processing of data (including in the area of profiling, market and statistical analyses), you make it easier at LOVA to find exactly what you are looking for and need to take care of your femininity and nurture your intimate and sexual health.\nThe administrator of your data will be LOVA and some partners with whom we cooperate."
  })), __jsx("p", null, t.formatMessage({
    id: "cookieConsent.main.description2",
    defaultMessage: "The data stored in cookies is used by us to facilitate the use of our websites, present personalized content and advertisements and measure them, create statistics, and improve the functionality of the website.\nMore information about the cookies used can be found in the {privacyPolicyLink}."
  }, {
    privacyPolicyLink: __jsx(Link, {
      href: path.page._slug(PAGE_PRIVACY_POLICY_SLUG).$url()
    }, t.formatMessage({
      id: "cookieConsent.main.description2.privacyPolicyLink",
      defaultMessage: "privacy policy"
    }))
  })))), __jsx("div", {
    className: styles.buttons()
  }, __jsx(Button, {
    variant: "oval",
    color: "flavor",
    onClick: handleAcceptClick,
    className: styles.acceptButton()
  }, t.formatMessage({
    id: "cookieConsent.main.submitButton",
    defaultMessage: "Thank you! I agree."
  })), __jsx("button", {
    onClick: handleAdvancedSettingsClick,
    className: styles.advancedSettingsButton()
  }, t.formatMessage({
    id: "cookieConsent.main.advancedSettingsButton",
    defaultMessage: "Go to advanced settings"
  }))));
};

Main.displayName = "Main";
export default Main;