import _slicedToArray from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";
var __jsx = React.createElement;
import isFunction from "lodash/isFunction";
import { useRouter } from "next/router";
import React from "react";
import { useIntl } from "react-intl";
import { Command } from "@/components/ui";
import usePath from "@/hooks/usePath.hook";
import { useSearch } from "@/hooks/useSearch.hook";
import { SEARCH_PRODUCTS_LIMIT } from "@/search/search.context";
import { pushGtmEvent } from "~/src/helpers/analytics.helper";
import { SearchProduct } from "..";
var SEARCH_INPUT_THRESHOLD = 3;

var SearchCommand = function SearchCommand(_ref) {
  var setGtmEventData = _ref.setGtmEventData;
  var t = useIntl();
  var path = usePath();
  var router = useRouter();

  var _React$useState = React.useState(""),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      searchInput = _React$useState2[0],
      setSearchInput = _React$useState2[1];

  var _useSearch = useSearch(),
      allProducts = _useSearch.allProducts,
      searchedProducts = _useSearch.searchedProducts,
      suggestedProducts = _useSearch.suggestedProducts,
      suggestedCollections = _useSearch.suggestedCollections,
      suggestedLinks = _useSearch.suggestedLinks,
      debouncedSearch = _useSearch.debouncedSearch,
      loading = _useSearch.loading,
      ready = _useSearch.ready,
      fetchSearchData = _useSearch.fetchSearchData;

  var inputRef = React.useRef(null);
  React.useEffect(function () {
    debouncedSearch({
      first: SEARCH_PRODUCTS_LIMIT,
      filter: {
        search: searchInput
      },
      sortBy: {
        direction: "ASC",
        field: "PRICE"
      }
    }, SEARCH_INPUT_THRESHOLD);

    if (isFunction(setGtmEventData)) {
      setGtmEventData(searchInput);
    }
  }, [searchInput, debouncedSearch, setGtmEventData]);
  var handleExternalLinkSelect = React.useCallback(function (value) {
    pushGtmEvent({
      event: "search_selected",
      search: {
        type: "external_link",
        value: value !== null && value !== void 0 ? value : "unknown"
      }
    });
    router.push(value);
  }, [router]);
  var handleCollectionSelect = React.useCallback(function (value, id) {
    pushGtmEvent({
      event: "search_selected",
      search: {
        type: "collection",
        value: value !== null && value !== void 0 ? value : "unknown"
      }
    });
    router.push(path.products._collectionId(id).$url());
  }, [path.products, router]);
  React.useEffect(function () {
    fetchSearchData();
  }, [fetchSearchData]);
  React.useEffect(function () {
    if (ready) {
      var _inputRef$current;

      (_inputRef$current = inputRef.current) === null || _inputRef$current === void 0 || _inputRef$current.focus();
    }
  }, [ready]);
  return __jsx(Command.Root, {
    loop: true,
    shouldFilter: searchInput.length > 0 && searchedProducts.length === 0
  }, __jsx(Command.Input, {
    ref: inputRef,
    value: searchInput,
    onValueChange: setSearchInput,
    placeholder: t.formatMessage({
      id: "search.input.placeholder",
      defaultMessage: "Szukaj produktów..."
    }),
    loading: loading,
    disabled: !ready
  }), ready ? searchInput.length > 0 ? __jsx(Command.List, null, __jsx(Command.Empty, null, t.formatMessage({
    id: "search.empty.title",
    defaultMessage: "Nie znaleziono produktów."
  })), __jsx(Command.Group, {
    heading: t.formatMessage({
      id: "search.searchedProducts.heading",
      defaultMessage: "Znalezione produkty"
    })
  }, (searchedProducts.length > 0 ? searchedProducts : allProducts).map(function (product) {
    return product !== null && product !== void 0 && product.variants ? product.variants.map(function (variant) {
      return __jsx(SearchProduct, {
        key: variant.id,
        variant: variant,
        product: product,
        searchInput: searchInput
      });
    }) : null;
  }))) : __jsx(Command.List, null, suggestedProducts.length > 0 ? __jsx(Command.Group, {
    heading: t.formatMessage({
      id: "search.sugesstedProducts.heading",
      defaultMessage: "Sugerowane produkty"
    })
  }, suggestedProducts.map(function (product) {
    return product !== null && product !== void 0 && product.variants ? product.variants.map(function (variant) {
      return __jsx(SearchProduct, {
        key: variant.id,
        variant: variant,
        product: product,
        searchInput: searchInput
      });
    }) : null;
  })) : null, suggestedCollections.length > 0 ? __jsx(React.Fragment, null, __jsx(Command.Separator, null), __jsx(Command.Group, {
    heading: t.formatMessage({
      id: "search.sugesstedCollections.heading",
      defaultMessage: "Sugerowane kategorie"
    })
  }, suggestedCollections.map(function (collection) {
    return __jsx(Command.Item, {
      key: collection.id,
      value: collection.name,
      onSelect: function onSelect() {
        return handleCollectionSelect(collection.name, collection.id);
      }
    }, collection.name);
  }))) : null, suggestedLinks.length > 0 ? __jsx(React.Fragment, null, __jsx(Command.Separator, null), __jsx(Command.Group, {
    heading: t.formatMessage({
      id: "search.sugesstedLinks.heading",
      defaultMessage: "Pozostałe sugestie"
    })
  }, suggestedLinks.map(function (link, index) {
    return __jsx(Command.Item, {
      key: "".concat(link.url.host, "-").concat(index),
      value: link.url.toString(),
      onSelect: function onSelect() {
        return handleExternalLinkSelect(link.url.toString());
      }
    }, link.label);
  }))) : null) : null);
};

SearchCommand.displayName = "SearchCommand";
export default SearchCommand;